import { statsTypes } from '../types';
import { AnyAction } from 'redux';
import { StatsReducer } from 'interfaces/strategyStatsShapes';
import { createRandomId } from '../../helpers/randomHelpers';

const initialState: StatsReducer = {
  accountStatsData: [],
  strategyStatsData: { count: null, results: [] },
  filters: {
    profiles: []
  },
  resultByMonths: {
    total: null,
    day_average: null,
    month_average: null,
    by_month: []
  },
  systemDataByMonths: {
    turn_over_at_value: 0,
    turn_over_month_value: 0,
    turn_over_week_value: 0,
    turn_over_day_value: 0,
    positive_months: 0,
    avg_capital_growth_rate: 0,
    worst_month_return: { date: '', value: 0 },
    best_month_return: { date: '', value: 0 },
    system_is_running_days: 0,
    system_start_date: ''
  },
  charts: {}
};

export default function statsReducer(state = initialState, { type, payload }: AnyAction): StatsReducer {
  switch (type) {
    case statsTypes.PUT_STRATEGY_STATS:
      return { ...state, strategyStatsData: payload };

    case statsTypes.PUT_ACCOUNT_STATS:
      return { ...state, accountStatsData: payload };

    case statsTypes.PUT_STRATEGY_STATS_FILTERS:
    case statsTypes.PUT_ACCOUNT_STATS_FILTERS:
      return { ...state, filters: payload };

    case statsTypes.PUT_RESULT_BY_MONTHS:
      return { ...state, resultByMonths: payload };

    case statsTypes.PUT_SYSTEM_DATA_BY_MONTHS:
      return { ...state, systemDataByMonths: payload };

    case statsTypes.PUT_STATS_GRAPH:
      return {
        ...state,
        charts: {
          [createRandomId()]: payload[0],
          ...(payload[1] && { [createRandomId()]: payload[1] })
        }
      };

    case statsTypes.CLEAN_CHARTS_DATA:
      return {
        ...state,
        charts: initialState.charts
      };

    default:
      return state;
  }
}
